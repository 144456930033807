import React, {useState} from "react";
import loadable from "@/component/loadable";
import {Route, Switch, Redirect, HashRouter} from "react-router-dom";

const home:any = loadable(() => import("@/page/home"));
const goodsLost:any = loadable(() => import("@/page/goodsLost"));
const datasWrong:any = loadable(() => import("@/page/datasWrong"));
const freezeAccount:any = loadable(() => import("@/page/freezeAccount"));
const silenced:any = loadable(() => import("@/page/silenced"));
const abnormalReport:any = loadable(() => import("@/page/abnormalReport"));
const loginWrong:any = loadable(() => import("@/page/loginWrong"));
const forgotPsw:any = loadable(() => import("@/page/forgetPsw"));
const forgotAccount:any = loadable(() => import("@/page/forgetAccount"));
const blockedQuery:any = loadable(() => import("@/page/blockedQuery"));
const changeMobile:any = loadable(() => import("@/page/changeMobile"));
const stolenComplain:any = loadable(() => import("@/page/stolenComplain"));
const selfFrozen:any = loadable(() => import("@/page/selfFrozen"));
const rechargeUnavailable:any = loadable(() => import("@/page/rechargeUnavailable"));
const payUnavailable:any = loadable(() => import("@/page/payUnavailable"));
const gameSuggest:any = loadable(() => import("@/page/gameSuggest"));
const complainServer:any = loadable(() => import("@/page/complainServer"));
const gameDownload:any = loadable(() => import("@/page/gameDownload"));
const userAuth:any = loadable(() => import("@/page/userAuth"));
const hotQuestion:any = loadable(() => import("@/page/hotQuestion"));
const hotQuestionDetail:any = loadable(() => import("@/page/hotQuestionDetail"));
const praiseWall:any = loadable(() => import("@/page/praiseWall"));
const praiseForm:any = loadable(() => import("@/page/praiseForm"));
const serverValid:any = loadable(() => import("@/page/serverValid"));
const searchResult:any = loadable(() => import("@/page/searchResult"));
const searchDetail:any = loadable(() => import("@/page/searchDetail"));
const Login:any = loadable(() => import("@/page/login"));
const roleBindLogin:any = loadable(() => import("@/page/roleBindLogin"));
const JudgeVIP:any = loadable(() => import("@/page/judgeVIP"));
const zoneSearch:any = loadable(() => import("@/page/zoneSearch"));
const welfareGet:any = loadable(() => import("@/page/welfareGet"));
const welfareGame:any = loadable(() => import("@/page/welfareGet/game"));
const welfareRewardList:any = loadable(() => import("@/page/welfareGet/rewardList"));
const selfChangeMobile:any = loadable(() => import("@/page/selfChangeMobile"));
const WechatComplaint:any = loadable(() => import("@/page/wechatComplaint"));

//配置主路由

export const Context = React.createContext<any>({
    comdata: "",
    changeData: "",
});
const Routes: React.FC = () => {
    const [comData, setData] = useState<any>();
    const changeData = (data: any) => {
        setData(data);
    };

    return (
        <HashRouter>
            <Context.Provider value={{comData, changeData}}>
                <Switch>
                    <Route exact path="/home" component={home}/>
                    <Route exact path="/goodsLost" component={goodsLost}/>
                    <Route exact path="/datasWrong" component={datasWrong}/>
                    <Route exact path="/freezeAccount" component={freezeAccount}/>
                    <Route exact path="/silenced" component={silenced}/>
                    <Route exact path="/abnormalReport" component={abnormalReport}/>
                    <Route exact path="/loginWrong" component={loginWrong}/>
                    <Route exact path="/forgotPsw" component={forgotPsw}/>
                    <Route exact path="/forgotAccount" component={forgotAccount}/>
                    <Route exact path="/blockedQuery" component={blockedQuery}/>
                    <Route exact path="/changeMobile" component={changeMobile}/>
                    <Route exact path="/stolenComplain" component={stolenComplain}/>
                    <Route exact path="/selfFrozen" component={selfFrozen}/>
                    <Route exact path="/rechargeUnavailable" component={rechargeUnavailable}/>
                    <Route exact path="/payUnavailable" component={payUnavailable}/>
                    <Route exact path="/gameSuggest" component={gameSuggest}/>
                    <Route exact path="/complainServer" component={complainServer}/>
                    <Route exact path="/gameDownload" component={gameDownload}/>
                    <Route exact path="/userAuth" component={userAuth}/>
                    <Route exact path="/hotQuestion" component={hotQuestion}/>
                    <Route exact path="/hotQuestionDetail/:id" component={hotQuestionDetail}/>
                    <Route exact path="/praiseWall" component={praiseWall}/>
                    <Route exact path="/praiseForm" component={praiseForm}/>
                    <Route exact path="/serverValid" component={serverValid}/>
                    <Route exact path="/searchResult" component={searchResult}/>
                    <Route exact path="/searchDetail/:id" component={searchDetail}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/roleBindLogin" component={roleBindLogin}/>
                    <Route exact path="/zoneSearch" component={zoneSearch}/>
                    <Route exact path="/welfareGet/game" component={welfareGame}/>
                    <Route exact path="/welfareGet/reward_list/:id" component={welfareRewardList}/>
                    <Route exact path="/welfareGet/:id" component={welfareGet}/>
                    <Route exact path="/selfChangeMobile" component={selfChangeMobile}/>
                    <Route exact path="/judgeVIP" component={JudgeVIP}/>
                    <Route exact path="/judgeVIP" component={JudgeVIP}/>
                    <Route exact path="/wechatComplaint" component={WechatComplaint}/>
                    <Redirect to="/judgeVIP"/>
                </Switch>
            </Context.Provider>
        </HashRouter>
    );
};
export default Routes;
