import Loadable from 'react-loadable';
import {Icon} from 'antd-mobile-v2';

export default (loader:any,render?:any)=>{
    if(!render){
        return Loadable({
            loader,
            loading: () => (<Icon type="loading" className="loading" size="lg" />)
        })
    }
    return Loadable({
        loader,
        loading: () => (<Icon type="loading" className="loading" size="lg" />),
        render
    })
}