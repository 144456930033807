import ReactDOM from 'react-dom';
import Routes from './router';
import './global.less';
import 'lib-flexible';
import './assets/js/gt';

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);
